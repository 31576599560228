<script>
  import {onMount} from 'svelte'
  import Accordions from "./Accordions.svelte";
  import Splide from '@splidejs/splide';

  let sliderMobile;
  let selected = 0;

  export let blocks;
  export let componentData = null
  export let dividerAbove = false;
  export let dividerBelow = false;

  const BASE_URL = import.meta.env.PUBLIC_BASE_URL;
const BASE_SELF_URL = import.meta.env.PUBLIC_BASE_SELF_URL;


  if(componentData){
    dividerAbove = componentData && componentData.componentData && componentData.componentData.SectionDivider ? componentData.componentData.SectionDivider.DividerUpper : null
    dividerBelow = componentData && componentData.componentData && componentData.componentData.SectionDivider ? componentData.componentData.SectionDivider.DividerBottom : null

    let sliderData = componentData.sliderData.data
    blocks = sliderData
            ? sliderData.map(function(data) {
              return {
                title: data.attributes.Title,
                text: '<p>'+data.attributes.Text+'</p>',
                btnText: data?.attributes?.Button_Text ?? '',
                btnLink: data?.attributes?.Button_Link ?? '',
                btnStyle: 'btn btn--outline',
                imgSrc: BASE_URL + (data?.attributes?.Image?.data?.attributes?.url ?? ''),
                imgAlt: 'image'
              };
            })
            : [];
  }
  let progressBarContainer;
  onMount(async () => {
    const slider = new Splide(sliderMobile, {
      pagination: false,
      drag: true,
      perMove: 1,
      arrows: true,
      classes: {
        arrow: 'splide__arrow',
      },
    });

    slider.mount();

    // Wait a short time for the Splide mount to complete.
    await new Promise(resolve => setTimeout(resolve, 100));

    // Create a progress bar for each slide
    const progressBars = [];  // array to store progress bar elements
    for (let i = 0; i < slider.length; i++) {
      const progressBar = document.createElement('div');
      progressBar.style.flex = 1;
      progressBar.style.height = '2px';
      progressBar.style.backgroundColor = '#DA5D5B';
      progressBars.push(progressBar);  // add progress bar element to array
      progressBarContainer.appendChild(progressBar);
    }

    // Function to update progress bar height based on the current index
    function updateHeight() {
      const index = slider.index;
      progressBars.forEach((bar, i) => {
        bar.style.height =  (i === index) ? '6px' : '2px';
      })
    }

    updateHeight();  // call function initially to set correct height

    // Listen for when Splide moves to a different slide
    slider.on('moved', () => {
      updateHeight();  // call function to update height when slide changes
    });
  });

</script>

<style lang="scss">

  .parent-container {
    display: flex;
    justify-content: center;
  }

  .slider-progress-container {
    z-index: 1;
    display: flex;
    gap: 5px;
    position: relative;
    top: 35px;
    width: 95px;
  }


  .accordion-slider {
    position: relative;
    overflow: hidden;
    height: calc(100vh - var(--header-height));
    max-height: 735px;
    color: var(--color-primary);

    @media screen and (min-width: 1024px) {
      display: flex;
    }

    @media (max-width: 1023px) {
      overflow: visible;
    }

    .slider-progress {
      display: none;
      position: absolute;
      bottom: auto;
      top: 25px;
    }
  }

  .accordion-slide {
    position: relative;
    overflow: hidden;

    @media screen and (min-width: 1024px) {
      transition: flex 0.6s ease;
      flex: 1 1 calc(50% / 3);

      &.is-selected {
        flex: 0 0 50%;
        transition: flex .6s ease;
      }
    }
  }

  .accordion-slide__content,
  .accordion-slide__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .accordion-slide__bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: auto;
    right: 0;
  }

  .accordion-slide__bg img {
    width: 100%;
    height: 100%;

    @media screen and (min-width: 1024px) {
      position: relative;
      width: 50vw;
      max-width: calc(var(--container-width) / 2);
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .accordion-slide__content {
    display: flex;
    flex-wrap: wrap;
    align-content: end;
    padding: 30px var(--inner);
    background: linear-gradient(180deg, rgba(128, 128, 128, 0) 0%, rgba(69 69 69 / 50%) 60.94%);
    background-blend-mode: multiply;

    p {
      display: block;
      margin: 0;
  }

    h2 {
      margin: 0 0 6px;
    }
  }

  .accordion-slide__content-outer {
    @media screen and (min-width: 1024px) {
      min-width: calc(var(--container-width) / 2 - var(--inner) * 2);
      visibility: hidden;
      transition: visibility 0.4s;

      & > * {
        width: 100%;
        opacity: 0;
        transform: translateY(15px);
        transition: opacity .4s ease, transform .4s ease;
      }
    }

    @media screen and (min-width: 1024px) and (max-width: 1728px) {
      min-width: calc(50vw - var(--inner) * 2);
    }
  }

  .accordion-slide__content-inner {
    @media screen and (min-width: 1024px) {
      max-width: 75%;
    }
  }

  .accordion-slide__text {
    font-size: 14px;
    line-height: 1.17;
    margin-bottom: 9px;
  }

  @media screen and (min-width: 1024px) {
    .is-selected .accordion-slide__content-outer {
      visibility: visible;
      transition: visibility .6s;

      & > * {
        opacity: 1;
        transform: none;
        transition-duration: .4s;
      }
    }
  }

  .accordion-slide__text {
    margin-top: 15px;
  }
  .accordion-slide__button {
    margin-top: 25px;
  }

  .accordion-slide__content-inner {
    @media screen and (min-width: 750px) and (max-width: 1023px) {
      max-width: 75%;
    }
  }

  @for $i from 1 through 15 {
    .is-selected .accordion-slide__content-outer > *:nth-child(#{$i}) {
      @media screen and (min-width: 1024px) {
        transition-delay: calc(#{$i} * 100ms + 100ms);
      }
    }
  }
  .splide__arrows{
    display: none;
  }
</style>

<section
  class:divider-above={dividerAbove}
  class:divider-below={dividerBelow}>
  <div class="accordion-slider desktop">
    {#each blocks as block, index}
      <a href="{block.btnLink}" class="accordion-slide"
        class:is-selected={index == selected}
        on:mouseenter={() => {selected = index}}
      >
        {#if block.imgSrc}
          <div class="accordion-slide__bg">
            <img width="1920" height="1080" src="{block.imgSrc}" alt="{block.imgAlt}" loading="lazy">
          </div>
        {/if}

        <div class="accordion-slide__content">
          <div class="accordion-slide__content-outer">
            <div class="accordion-slide__content-inner">
              {#if block.title}
                <h2>{block.title}</h2>
              {/if}

              {#if block.text}
                <div class="accordion-slide__text">
                  {@html block.text}
                </div>
              {/if}

              {#if block.btnText}
                <div class="accordion-slide__button">
                  <span class="{block.btnStyle}">{block.btnText}</span>
                </div>
              {/if}
            </div>
          </div>
        </div>
      </a>
    {/each}
  </div>

  <div class="tablet-down">
    <div class="accordion-slider">

      <div class="parent-container">
        <div class="slider-progress-container" bind:this={progressBarContainer}></div>
      </div>

      <div class="splide" bind:this={sliderMobile}>


        <div class="splide__track">
          <div class="splide__list accordion-slider">
            {#each blocks as block, index}
              <a href="{block.btnLink}" class="splide__slide accordion-slide">

                {#if block.imgSrc}
                  <div class="accordion-slide__bg">
                    <img width="1920" height="1080" src="{block.imgSrc}" alt="{block.imgAlt}" loading="lazy">
                  </div>
                {/if}

                <div class="accordion-slide__content">
                  <div class="accordion-slide__content-inner">
                    {#if block.title}
                      <h2>{block.title}</h2>
                    {/if}

                    {#if block.text}
                      <div class="accordion-slide__text">
                        {@html block.text}
                      </div>
                    {/if}

                    {#if block.btnText}
                      <div class="accordion-slide__button">
                        <span class="{block.btnStyle}">{block.btnText}</span>
                      </div>
                    {/if}
                  </div>
                </div>
              </a>
            {/each}
          </div>
        </div>

        <div class="slider-progress">
          <div class="slider-progress-bar"></div>

        </div>
      </div>
    </div>
  </div>
  
</section>